import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  Card,
  CardSection,
  Button,
  ButtonGroup,
  InputField,
  InputFile,
  Slider,
  Loading,
  Stack,
  Heading,
} from "@kiwicom/orbit-components";

// Components
import Wrapper from "../../components/Content";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { Container } from "../../components/Content/styles";
// Utils
import {
  editHorse,
  getHorseById,
  getHorseImagesById,
  deleteHorse,
  deleteHorseImage,
  createHorseImage,
} from "../../utils/api/horse";
// Types
import { Horse as HorseType } from "../../static/types/horse";
import { Image as ImageType } from "../../static/types/image";

// Styles
import { Content, PreviewImage, ImagesWrapper, Image } from "./Styled";

const HorseEdit = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const [{ token }] = useCookies(["token"]);

  // State
  const [horse, setHorse] = useState<HorseType | null>(null);
  const [images, setImages] = useState<ImageType[]>([]);
  const [birth, setBirth] = useState<string>("");
  const [sex, setSex] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [price, setPrice] = useState<number>(1);
  const [title, setTitle] = useState<string>(horse ? horse.title : "");
  const [thumbImage, setThumbImage] = useState<any>(null);
  const [horseImage, setHorseImage] = useState<any>(null);
  const [editor, setEditor] = useState<any>();
  const [type, setType] = useState<string>("blog");

  // Params
  const { pathname } = useLocation();
  const horseId = pathname.split("/")[2];

  if (!token) {
    window.location.replace("/login");
  }

  useEffect(() => {
    if (horseId) {
      // Fetchs blog post
      getHorseById(Number(horseId), setHorse, setLoading);
      // Fetchs blog post images
      getHorseImagesById(Number(horseId), setImages, setLoading);
    }
  }, [horseId]);

  useEffect(() => {
    if (horse) {
      setTitle(horse.title);
      setType(horse.type);
      setBirth(horse.birth);
      setSex(horse.sex);
      setPrice(Number(horse.price));

      if (horse.video) {
        setVideo(horse.video);
      }

      const contentBlock = htmlToDraft(horse.description);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      setEditor(editorState);
    }
  }, [horse]);

  const handleThumbImageChange = useCallback((e: any) => {
    setThumbImage(e.target.files[0]);
  }, []);

  const handleHorseImageChange = useCallback((e: any) => {
    setHorseImage(e.target.files[0]);
  }, []);

  const handleSubmit = useCallback(() => {
    const contentRaw = draftToHtml(convertToRaw(editor.getCurrentContent()));
    editHorse(
      horseId,
      {
        title,
        price,
        birth,
        sex,
        video,
        description: contentRaw,
        type,
        image: thumbImage,
      },
      token,
      setLoading
    );
  }, [
    title,
    price,
    birth,
    sex,
    video,
    editor,
    type,
    thumbImage,
    token,
    horseId,
  ]);

  const handleImageUpload = useCallback(() => {
    createHorseImage({ id: horseId, image: horseImage }, token, setLoading);
  }, [horseImage, token, horseId]);

  const handleDelete = useCallback(() => {
    deleteHorse(Number(horseId), token, setLoading);
  }, [horseId, token]);

  const handleImageDelete = useCallback(
    (id: number) => {
      deleteHorseImage(id, token, setLoading);
    },
    [token]
  );

  const handleEditorChange = useCallback((editorState: any) => {
    setEditor(editorState);
  }, []);

  if (!horse) {
    return (
      <Wrapper>
        <Content>
          <Container animate>
            <Loading type="pageLoader" />
          </Container>
        </Content>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Content>
        <Container animate>
          <Stack>
            {deleteAction && (
              <ConfirmModal
                action={handleDelete}
                cancel={() => setDeleteAction(false)}
              />
            )}
            {loading && <Loading type="pageLoader" />}
            {!loading && (
              <Card>
                <CardSection>
                  <InputField
                    value={title}
                    label="Title"
                    onChange={(e: any) => setTitle(e.target.value)}
                    placeholder="Title"
                  />
                </CardSection>
                <CardSection>
                  <InputField
                    value={birth}
                    label="Birth"
                    onChange={(e: any) => setBirth(e.target.value)}
                    placeholder="Birth"
                  />
                </CardSection>
                <CardSection>
                  <ButtonGroup>
                    <Button
                      onClick={() => setSex("m")}
                      type={sex === "m" ? "primary" : "secondary"}
                    >
                      Male
                    </Button>
                    <Button
                      onClick={() => setSex("f")}
                      type={sex === "f" ? "primary" : "secondary"}
                    >
                      Female
                    </Button>
                  </ButtonGroup>
                </CardSection>
                <CardSection>
                  <InputField
                    value={video}
                    label="Youtube video"
                    onChange={(e: any) => setVideo(e.target.value)}
                    placeholder="Video"
                  />
                </CardSection>
                <CardSection>
                  <Editor
                    editorState={editor}
                    onEditorStateChange={handleEditorChange}
                    toolbar={{
                      options: [
                        "inline",
                        "textAlign",
                        "link",
                        "colorPicker",
                        "fontSize",
                      ],
                      fontSize: {
                        options: [10, 12, 14, 16, 24, 32, 40, 48, 52, 60, 68],
                      },
                    }}
                  />
                </CardSection>
                <CardSection>
                  <Slider
                    defaultValue={price}
                    label={`Price ( ${price} )`}
                    onChange={(e: any) => setPrice(e)}
                    minValue={1}
                    maxValue={5}
                  />
                </CardSection>
                <CardSection>
                  <Stack direction="row">
                    <PreviewImage
                      src={`${process.env.REACT_APP_STORAGE_BASE}/horse-thumb/${horse.image}`}
                      alt="Avatar"
                    />
                    <InputFile
                      label="Horse thumb image"
                      onChange={handleThumbImageChange}
                      fileName={thumbImage?.name}
                    />
                  </Stack>
                </CardSection>
                <CardSection>
                  <Stack direction="row" justify="between">
                    <Button type="secondary" onClick={handleSubmit} fullWidth>
                      Save
                    </Button>
                    <Button
                      type="critical"
                      onClick={() => setDeleteAction(true)}
                    >
                      Delete
                    </Button>
                  </Stack>
                </CardSection>
              </Card>
            )}
            {!loading && (
              <Card>
                <CardSection>
                  <Heading>Upload blog imges</Heading>
                </CardSection>
                <CardSection>
                  <ImagesWrapper>
                    {images.map((image) => (
                      <Stack spacing="XSmall">
                        <Image
                          backgroundImage={`${process.env.REACT_APP_STORAGE_BASE}/horse/${image.path}`}
                        />
                        <Button
                          onClick={() => handleImageDelete(image.id)}
                          fullWidth
                        >
                          Delete
                        </Button>
                      </Stack>
                    ))}
                  </ImagesWrapper>
                </CardSection>
                <CardSection>
                  <InputFile
                    label="Upload Horse image"
                    onChange={handleHorseImageChange}
                    fileName={horseImage?.name}
                  />
                </CardSection>
                <CardSection>
                  <Stack direction="row" justify="between">
                    <Button
                      type="secondary"
                      onClick={handleImageUpload}
                      fullWidth
                    >
                      Upload
                    </Button>
                  </Stack>
                </CardSection>
              </Card>
            )}
          </Stack>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default HorseEdit;
