import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  Stack,
  Box,
  Button,
  Heading,
  Loading,
} from "@kiwicom/orbit-components";

// Utils
import { getHorses, getRepnikHorses } from "../../utils/api/horse";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Types
import { Horse as HorseType } from "../../static/types/horse";

// Styles
import { Content, Cards, Card as HorseCard, CardOverlay } from "./Styled";

const Catalog = () => {
  const [{ token }] = useCookies(["token"]);
  const [loading, setLoading] = useState<boolean>(false);
  const [horses, setHorses] = useState<HorseType[]>([]);
  const [repnikHorses, setRepnikHorses] = useState<HorseType[]>([]);

  if (!token) {
    window.location.replace("/login");
  }

  useEffect(() => {
    getHorses(setHorses, setLoading);
    getRepnikHorses(setRepnikHorses, setLoading);
  }, []);

  return (
    <Wrapper>
      <Content>
        <Container animate>
          <Stack spacing="XLarge">
            <Stack>
              <Box
                padding={{ left: "medium", right: "medium" }}
                tablet={{ padding: { left: "none", right: "none" } }}
              >
                <Stack direction="row" align="center">
                  <Heading>Hof der Lipizzaner</Heading>
                  <a href="/create/horse">
                    <Button size="small">Create</Button>
                  </a>
                </Stack>
              </Box>

              <Box
                padding={{ left: "medium", right: "medium" }}
                tablet={{ padding: { left: "none", right: "none" } }}
              >
                <Stack spacing="XLarge">
                  {loading && <Loading type="pageLoader" />}
                  {!loading && horses.length && (
                    <Cards>
                      {horses.map((horse: HorseType) => (
                        <HorseCard
                          key={horse.id}
                          backgroundImage={`${process.env.REACT_APP_STORAGE_BASE}/horse-thumb/${horse.image}`}
                        >
                          <CardOverlay to={`/catalog/${horse.id}`}>
                            <Stack>
                              <Heading type="title2" inverted>
                                {horse.title}
                              </Heading>
                              <Button size="small">See more</Button>
                            </Stack>
                          </CardOverlay>
                        </HorseCard>
                      ))}
                    </Cards>
                  )}
                </Stack>
              </Box>
            </Stack>
            <Stack>
              <Box
                padding={{ left: "medium", right: "medium" }}
                tablet={{ padding: { left: "none", right: "none" } }}
              >
                <Stack direction="row" align="center">
                  <Heading>Repnik</Heading>
                  <Link to="/create/horse">
                    <Button size="small">Create</Button>
                  </Link>
                </Stack>
              </Box>

              <Box
                padding={{ left: "medium", right: "medium" }}
                tablet={{ padding: { left: "none", right: "none" } }}
              >
                <Stack spacing="XLarge">
                  {loading && <Loading type="pageLoader" />}
                  {!loading && repnikHorses.length && (
                    <Cards>
                      {repnikHorses.map((horse: HorseType) => (
                        <HorseCard
                          key={horse.id}
                          backgroundImage={`${process.env.REACT_APP_STORAGE_BASE}/horse-thumb/${horse.image}`}
                        >
                          <CardOverlay to={`/catalog/${horse.id}`}>
                            <Stack>
                              <Heading type="title2" inverted>
                                {horse.title}
                              </Heading>
                              <Button size="small">See more</Button>
                            </Stack>
                          </CardOverlay>
                        </HorseCard>
                      ))}
                    </Cards>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default Catalog;
