import axios from "axios";

export const getBlogPosts = async (
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/blog`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getReferencePosts = async (
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/reference`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getBlogById = async (
  id: number,
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/blog/${id}`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getBlogImagesById = async (
  id: number,
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/images/type/blog/${id}`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const deleteBlog = async (
  id: number,
  token: string,
  setLoading: Function
) => {
  try {
    setLoading && setLoading(true);

    await axios.delete(
      `${process.env.REACT_APP_API_BASE}/api/secure/blog/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    window.location.replace("/blog");
  } catch (error) {
    console.error(error);
  }
};

export const deleteBlogImage = async (
  id: number,
  token: string,
  setLoading: Function
) => {
  try {
    setLoading && setLoading(true);

    await axios.delete(
      `${process.env.REACT_APP_API_BASE}/api/secure/images/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};

export const createBlogImage = async (
  { image, id }: any,
  token: string,
  setLoading: Function
) => {
  try {
    setLoading && setLoading(true);

    const formData = new FormData();
    formData.append("image[]", image);
    formData.append("type", "blog");
    formData.append("type_id", id);

    await axios.post(
      `${process.env.REACT_APP_API_BASE}/api/secure/images/create`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};

export const createBlog = async (
  { title, thumb, image, content, type }: any,
  token: string,
  setLoading: Function
) => {
  try {
    setLoading && setLoading(true);

    const formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("thumb", thumb);
    formData.append("content", content);
    formData.append("type", type);

    const {
      data: { id },
    } = await axios.post(
      `${process.env.REACT_APP_API_BASE}/api/secure/blog`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    window.location.replace(`/blog/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const editBlog = async (
  id: string,
  { title, thumb, image, content, type }: any,
  token: string,
  setLoading: Function
) => {
  try {
    setLoading && setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("thumb", thumb);
    formData.append("content", content);
    formData.append("type", type);

    if (image) {
      formData.append("image", image);
    }

    await axios.post(
      `${process.env.REACT_APP_API_BASE}/api/secure/blog/${id}/update`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};
