import axios from "axios";

export const getNewsletterEmails = async (
  token: string,
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/secure/newsletter`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};
