import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

export const Content = styled.div`
  z-index: 3;
  padding: 48px 0px;

  ${media.tablet(css`
    padding: 48px 16px;
  `)}

  .rdw-editor-main {
    min-height: 400px;
    padding: 0 16px;
    cursor: pointer;
    background-color: #ecf0f1;
  }
`;
