import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  Card,
  CardSection,
  Textarea,
  Button,
  InputField,
  InputFile,
  Select,
  Loading,
  Stack,
  Heading,
} from "@kiwicom/orbit-components";

// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Utils
import {
  editBlog,
  getBlogById,
  getBlogImagesById,
  deleteBlog,
  deleteBlogImage,
  createBlogImage,
} from "../../utils/api/blog";
// Types
import { Blog as BlogType } from "../../static/types/blog";
import { Image as ImageType } from "../../static/types/image";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

// Styles
import { Content, PreviewImage, ImagesWrapper, Image } from "./Styled";

const BlogEdit = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const [{ token }] = useCookies(["token"]);

  // State
  const [post, setPosts] = useState<BlogType | null>(null);
  const [images, setImages] = useState<ImageType[]>([]);
  const [thumbImage, setThumbImage] = useState<any>(null);
  const [blogImage, setBlogImage] = useState<any>(null);
  const [title, setTitle] = useState<string>(post ? post.title : "");
  const [thumb, setThumb] = useState<string>("");
  const [editor, setEditor] = useState<any>();
  const [type, setType] = useState<string>("blog");

  // Params
  const { pathname } = useLocation();
  const blogId = pathname.split("/")[2];

  if (!token) {
    window.location.replace("/login");
  }

  useEffect(() => {
    if (blogId) {
      // Fetchs blog post
      getBlogById(Number(blogId), setPosts, setLoading);
      // Fetchs blog post images
      getBlogImagesById(Number(blogId), setImages, setLoading);
    }
  }, [blogId]);

  useEffect(() => {
    if (post) {
      setTitle(post.title);
      setThumb(post.thumb);
      setType(post.type);

      const contentBlock = htmlToDraft(post.content);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      setEditor(editorState);
    }
  }, [post]);

  const handleThumbImageChange = useCallback((e: any) => {
    setThumbImage(e.target.files[0]);
  }, []);

  const handleBlogImageChange = useCallback((e: any) => {
    setBlogImage(e.target.files[0]);
  }, []);

  const handleSubmit = useCallback(() => {
    const contentRaw = draftToHtml(convertToRaw(editor.getCurrentContent()));
    editBlog(
      blogId,
      { title, thumb, content: contentRaw, type, image: thumbImage },
      token,
      setLoading
    );
  }, [title, thumb, type, thumbImage, editor, token, blogId]);

  const handleImageUpload = useCallback(() => {
    createBlogImage({ id: blogId, image: blogImage }, token, setLoading);
  }, [blogImage, token, blogId]);

  const handleDelete = useCallback(() => {
    deleteBlog(Number(blogId), token, setLoading);
  }, [blogId, token]);

  const handleImageDelete = useCallback(
    (id: number) => {
      deleteBlogImage(id, token, setLoading);
    },
    [token]
  );

  const handleEditorChange = useCallback((editorState: any) => {
    setEditor(editorState);
  }, []);

  if (!post) {
    return (
      <Wrapper>
        <Content>
          <Container animate>
            <Loading type="pageLoader" />
          </Container>
        </Content>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Content>
        <Container animate>
          <Stack>
            {deleteAction && (
              <ConfirmModal
                action={handleDelete}
                cancel={() => setDeleteAction(false)}
              />
            )}
            {loading && <Loading type="pageLoader" />}
            {!loading && (
              <Card>
                <CardSection>
                  <InputField
                    value={title}
                    label="Title"
                    onChange={(e: any) => setTitle(e.target.value)}
                    placeholder="Title"
                  />
                </CardSection>
                <CardSection>
                  <Textarea
                    value={thumb}
                    label="Thumb text"
                    onChange={(e: any) => setThumb(e.target.value)}
                    placeholder="Thumb"
                  />
                </CardSection>
                <CardSection>
                  <Editor
                    editorState={editor}
                    onEditorStateChange={handleEditorChange}
                    toolbar={{
                      options: [
                        "inline",
                        "textAlign",
                        "link",
                        "colorPicker",
                        "fontSize",
                      ],
                      fontSize: {
                        options: [10, 12, 14, 16, 24, 32, 40, 48, 52, 60, 68],
                      },
                    }}
                  />
                </CardSection>
                <CardSection>
                  <Select
                    value={type}
                    label="Blog / Reference"
                    onChange={(e: any) => setType(e.target.value)}
                    options={[
                      { value: "blog", label: "Blog" },
                      { value: "reference", label: "Reference" },
                    ]}
                  />
                </CardSection>
                <CardSection>
                  <Stack direction="row">
                    <PreviewImage
                      src={`${process.env.REACT_APP_STORAGE_BASE}/blog-thumb/${post.image}`}
                      alt="Avatar"
                    />
                    <InputFile
                      label="Blog thumb image"
                      onChange={handleThumbImageChange}
                      fileName={thumbImage?.name}
                    />
                  </Stack>
                </CardSection>
                <CardSection>
                  <Stack direction="row" justify="between">
                    <Button type="secondary" onClick={handleSubmit} fullWidth>
                      Save
                    </Button>
                    <Button
                      type="critical"
                      onClick={() => setDeleteAction(true)}
                    >
                      Delete
                    </Button>
                  </Stack>
                </CardSection>
              </Card>
            )}
            {!loading && (
              <Card>
                <CardSection>
                  <Heading>Upload blog imges</Heading>
                </CardSection>
                <CardSection>
                  <ImagesWrapper>
                    {images.map((image) => (
                      <Stack spacing="XSmall">
                        <Image
                          backgroundImage={`${process.env.REACT_APP_STORAGE_BASE}/blog/${image.path}`}
                        />
                        <Button
                          onClick={() => handleImageDelete(image.id)}
                          fullWidth
                        >
                          Delete
                        </Button>
                      </Stack>
                    ))}
                  </ImagesWrapper>
                </CardSection>
                <CardSection>
                  <InputFile
                    label="Upload Blog image"
                    onChange={handleBlogImageChange}
                    fileName={blogImage?.name}
                  />
                </CardSection>
                <CardSection>
                  <Stack direction="row" justify="between">
                    <Button
                      type="secondary"
                      onClick={handleImageUpload}
                      fullWidth
                    >
                      Upload
                    </Button>
                  </Stack>
                </CardSection>
              </Card>
            )}
          </Stack>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default BlogEdit;
