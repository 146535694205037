import React, { useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
  Card,
  CardSection,
  Textarea,
  Button,
  InputField,
  InputFile,
  Select,
  Loading,
} from "@kiwicom/orbit-components";

// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Utils
import { createBlog } from "../../utils/api/blog";

// Styles
import { Content } from "./Styled";

const BlogCreate = () => {
  const [{ token }] = useCookies(["token"]);
  const [image, setImage] = useState<any>(null);
  const [title, setTitle] = useState<string>("");
  const [thumb, setThumb] = useState<string>("");
  const [editor, setEditor] = useState<any>(EditorState.createEmpty());
  const [type, setType] = useState<string>("blog");
  const [loading, setLoading] = useState<boolean>(false);

  if (!token) {
    window.location.replace("/login");
  }

  const handleFileChange = useCallback((e: any) => {
    setImage(e.target.files[0]);
  }, []);

  const handleSubmit = useCallback(
    (e: any) => {
      const contentRaw = draftToHtml(convertToRaw(editor.getCurrentContent()));
      createBlog(
        { title, thumb, content: contentRaw, type, image },
        token,
        setLoading
      );
    },
    [title, thumb, type, image, editor, token]
  );

  const handleEditorChange = (editorState: any) => {
    setEditor(editorState);
  };

  return (
    <Wrapper>
      <Content>
        <Container animate>
          <Card>
            {loading && <Loading type="pageLoader" />}
            {!loading && (
              <>
                <CardSection>
                  <InputField
                    value={title}
                    label="Title"
                    onChange={(e: any) => setTitle(e.target.value)}
                    placeholder="Title"
                  />
                </CardSection>
                <CardSection>
                  <Textarea
                    value={thumb}
                    label="Thumb text"
                    onChange={(e: any) => setThumb(e.target.value)}
                    placeholder="Thumb"
                  />
                </CardSection>
                <CardSection>
                  <Editor
                    editorState={editor}
                    onEditorStateChange={handleEditorChange}
                    toolbar={{
                      options: [
                        "inline",
                        "textAlign",
                        "link",
                        "colorPicker",
                        "fontSize",
                      ],
                      fontSize: {
                        options: [10, 12, 14, 16, 24, 32, 40, 48, 52, 60, 68],
                      },
                    }}
                  />
                </CardSection>
                <CardSection>
                  <Select
                    value={type}
                    label="Blog / Reference"
                    onChange={(e: any) => setType(e.target.value)}
                    options={[
                      { value: "blog", label: "Blog" },
                      { value: "reference", label: "Reference" },
                    ]}
                  />
                </CardSection>
                <CardSection>
                  <InputFile
                    label="Blog thumb image"
                    onChange={handleFileChange}
                    fileName={image?.name}
                  />
                </CardSection>
                <CardSection>
                  <Button onClick={handleSubmit}>Create</Button>
                </CardSection>
              </>
            )}
          </Card>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default BlogCreate;
