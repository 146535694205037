import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { format } from "date-fns";
import {
  Card,
  CardSection,
  Stack,
  Text,
  Box,
  Button,
  Heading,
  Separator,
  Loading,
} from "@kiwicom/orbit-components";

// Utils
import { getBlogPosts } from "../../utils/api/blog";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Types
import { Blog as BlogType } from "../../static/types/blog";

// Styles
import { Content, BlogImage, BlogLink } from "./Styled";

const Blog = () => {
  const [{ token }] = useCookies(["token"]);
  const [loading, setLoading] = useState<boolean>(false);
  const [posts, setPosts] = useState<BlogType[]>([]);

  if (!token) {
    window.location.replace("/login");
  }

  useEffect(() => {
    getBlogPosts(setPosts, setLoading);
  }, []);

  return (
    <Wrapper>
      <Content>
        <Container animate>
          <Stack>
            <Box
              padding={{ left: "medium", right: "medium" }}
              tablet={{ padding: { left: "none", right: "none" } }}
            >
              <Stack direction="row" align="center">
                <Heading>Blogs</Heading>
                <Link to="/create/blog">
                  <Button size="small">Create</Button>
                </Link>
              </Stack>
            </Box>
            <Card>
              {loading && <Loading type="pageLoader" />}
              {posts.length &&
                posts.map((post: BlogType) => (
                  <CardSection key={post.id}>
                    <BlogLink to={`/blog/${post.id}`}>
                      <Stack direction="column" tablet={{ direction: "row" }}>
                        <BlogImage
                          url={`${process.env.REACT_APP_STORAGE_BASE}/blog-thumb/${post.image}`}
                        />
                        <Stack spacing="XSmall">
                          <Text size="small" type="secondary">
                            {format(new Date(post.created_at), "PPP")}
                          </Text>
                          <Heading
                            as="h3"
                            type="title2"
                            tablet={{ type: "title1" }}
                          >
                            {post.title}
                          </Heading>
                          <Separator />
                          <Text>
                            <div
                              dangerouslySetInnerHTML={{ __html: post.thumb }}
                            />
                          </Text>
                          <Button size="small">Edit this blog</Button>
                        </Stack>
                      </Stack>
                    </BlogLink>
                  </CardSection>
                ))}
            </Card>
          </Stack>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default Blog;
