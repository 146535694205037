import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

export const Content = styled.div`
  z-index: 3;
  padding: 48px 0px;

  ${media.tablet(css`
    padding: 48px 16px;
  `)}
`;

export const PreviewImage = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 10px;
`;

export const ImagesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
`;

export const Image = styled.div<{
  backgroundImage: string;
}>`
  width: 100%;
  height: 200px;
  background-image: url(${({ backgroundImage }: any) => backgroundImage});
  background-position: top;
  background-size: cover;
  border-radius: 10px;
`;
