import axios from "axios";

export const getHorses = async (setData: Function, setLoading?: Function) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/horse`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getRepnikHorses = async (
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/horse-repnik`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getHorseById = async (
  id: number,
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/horse/${id}`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getHorseImagesById = async (
  id: number,
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/images/type/horse/${id}`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const deleteHorse = async (
  id: number,
  token: string,
  setLoading: Function
) => {
  try {
    setLoading && setLoading(true);

    await axios.delete(
      `${process.env.REACT_APP_API_BASE}/api/secure/horse/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    window.location.replace("/catalog");
  } catch (error) {
    console.error(error);
  }
};

export const deleteHorseImage = async (
  id: number,
  token: string,
  setLoading: Function
) => {
  try {
    setLoading && setLoading(true);

    await axios.delete(
      `${process.env.REACT_APP_API_BASE}/api/secure/images/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};

export const createHorseImage = async (
  { image, id }: any,
  token: string,
  setLoading: Function
) => {
  try {
    setLoading && setLoading(true);

    const formData = new FormData();
    formData.append("image[]", image);
    formData.append("type", "horse");
    formData.append("type_id", id);

    await axios.post(
      `${process.env.REACT_APP_API_BASE}/api/secure/images/create`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};

export const createHorse = async (
  { title, price, image, description, birth, sex, type, video }: any,
  token: string,
  setLoading: Function
) => {
  try {
    setLoading && setLoading(true);

    const formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("price", price);
    formData.append("description", description);
    formData.append("birth", birth);
    formData.append("sex", sex);
    formData.append("type", type);

    if (video) {
      formData.append("video", video);
    }

    const {
      data: { id },
    } = await axios.post(
      `${process.env.REACT_APP_API_BASE}/api/secure/horse`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    window.location.replace(`/catalog/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const editHorse = async (
  id: string,
  { title, price, image, description, birth, sex, type, video }: any,
  token: string,
  setLoading: Function
) => {
  try {
    setLoading && setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("price", price);
    formData.append("description", description);
    formData.append("birth", birth);
    formData.append("sex", sex);
    formData.append("type", type);

    if (video) {
      formData.append("video", video);
    }

    if (image) {
      formData.append("image", image);
    }

    await axios.post(
      `${process.env.REACT_APP_API_BASE}/api/secure/horse/${id}/update`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};
