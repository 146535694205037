import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Button, Stack, Box } from "@kiwicom/orbit-components";

// Assets
import LogoIcon from "../../assets/logo-slim.jpg";
// Components
import { Container } from "../Content/styles";

// Styles
import { Wrapper, Branding } from "./styles";

const Navigation = () => {
  const [{ token }, , removeCookie] = useCookies(["token"]);

  const handleLogout = useCallback(() => {
    removeCookie("token", { path: "/" });
  }, [removeCookie]);

  if (!token) {
    return null;
  }

  return (
    <Wrapper>
      <Container directionRow spaceBetween>
        <Branding>
          <Link to="/">
            <img src={LogoIcon} alt="Hof der Lipizzaner" />
          </Link>
        </Branding>
        <Box display="inline-flex" align="center">
          <Stack direction="row" spacing="small">
            <Link to="/blog">
              <Button type="secondary" size="normal">
                Blogs
              </Button>
            </Link>
            <Link to="/reference">
              <Button type="secondary" size="normal">
                References
              </Button>
            </Link>
            <Link to="/catalog">
              <Button type="secondary" size="normal">
                Horses
              </Button>
            </Link>
            <Link to="/newsletter">
              <Button type="secondary" size="normal">
                Newsletter
              </Button>
            </Link>
          </Stack>
        </Box>
        <Button type="criticalSubtle" size="normal" onClick={handleLogout}>
          Logout
        </Button>
      </Container>
    </Wrapper>
  );
};

export default Navigation;
