import React, { useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
  Card,
  CardSection,
  Button,
  ButtonGroup,
  InputField,
  InputFile,
  Select,
  Loading,
  Slider,
} from "@kiwicom/orbit-components";

// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Utils
import { createHorse } from "../../utils/api/horse";

// Styles
import { Content } from "./Styled";

const HorseCreate = () => {
  const [{ token }] = useCookies(["token"]);
  const [image, setImage] = useState<any>(null);
  const [title, setTitle] = useState<string>("");
  const [birth, setBirth] = useState<string>("");
  const [sex, setSex] = useState<string>("m");
  const [video, setVideo] = useState<string>("");
  const [price, setPrice] = useState<number>(1);
  const [editor, setEditor] = useState<any>(EditorState.createEmpty());
  const [type, setType] = useState<string>("hof-der-lipizzaner");
  const [loading, setLoading] = useState<boolean>(false);

  if (!token) {
    window.location.replace("/login");
  }

  const handleFileChange = useCallback((e: any) => {
    setImage(e.target.files[0]);
  }, []);

  const handleSubmit = useCallback(
    (e: any) => {
      const contentRaw = draftToHtml(convertToRaw(editor.getCurrentContent()));
      createHorse(
        {
          title,
          birth,
          sex,
          price,
          video,
          description: contentRaw,
          type,
          image,
        },
        token,
        setLoading
      );
    },
    [title, birth, sex, type, image, editor, price, video, token]
  );

  const handleEditorChange = (editorState: any) => {
    setEditor(editorState);
  };

  return (
    <Wrapper>
      <Content>
        <Container animate>
          <Card>
            {loading && <Loading type="pageLoader" />}
            {!loading && (
              <>
                <CardSection>
                  <InputField
                    value={title}
                    label="Title"
                    onChange={(e: any) => setTitle(e.target.value)}
                    placeholder="Title"
                  />
                </CardSection>
                <CardSection>
                  <InputField
                    value={birth}
                    label="Birth"
                    onChange={(e: any) => setBirth(e.target.value)}
                    placeholder="Birth"
                  />
                </CardSection>
                <CardSection>
                  <ButtonGroup>
                    <Button
                      onClick={() => setSex("m")}
                      type={sex === "m" ? "primary" : "secondary"}
                    >
                      Male
                    </Button>
                    <Button
                      onClick={() => setSex("f")}
                      type={sex === "f" ? "primary" : "secondary"}
                    >
                      Female
                    </Button>
                  </ButtonGroup>
                </CardSection>
                <CardSection>
                  <InputField
                    value={video}
                    label="Youtube video"
                    onChange={(e: any) => setVideo(e.target.value)}
                    placeholder="Video"
                  />
                </CardSection>
                <CardSection>
                  <Slider
                    defaultValue={price}
                    label={`Price ( ${price} )`}
                    onChange={(e: any) => setPrice(e)}
                    minValue={1}
                    maxValue={5}
                  />
                </CardSection>
                <CardSection>
                  <Editor
                    editorState={editor}
                    onEditorStateChange={handleEditorChange}
                    toolbar={{
                      options: [
                        "inline",
                        "textAlign",
                        "link",
                        "colorPicker",
                        "fontSize",
                      ],
                      fontSize: {
                        options: [10, 12, 14, 16, 24, 32, 40, 48, 52, 60, 68],
                      },
                    }}
                  />
                </CardSection>
                <CardSection>
                  <Select
                    value={type}
                    label="Hof der Lipizzaner / Repnik"
                    onChange={(e: any) => setType(e.target.value)}
                    options={[
                      {
                        value: "hof-der-lipizzaner",
                        label: "Hof der Lipizzaner",
                      },
                      { value: "repnik", label: "Repnik" },
                    ]}
                  />
                </CardSection>
                <CardSection>
                  <InputFile
                    label="Blog thumb image"
                    onChange={handleFileChange}
                    fileName={image?.name}
                  />
                </CardSection>
                <CardSection>
                  <Button onClick={handleSubmit}>Create</Button>
                </CardSection>
              </>
            )}
          </Card>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default HorseCreate;
