import React, { useCallback } from "react";
import Modal, { ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import { Heading, Text, Button, Stack } from "@kiwicom/orbit-components/lib";

type Props = {
  action(): void;
  cancel(): void;
};

const ConfirmModal = ({ action, cancel }: Props) => {
  const handleConfirm = useCallback(() => {
    cancel();
    action();
  }, [cancel, action]);

  return (
    <Modal onClose={cancel}>
      <ModalSection>
        <Stack>
          <Heading>Are you sure?</Heading>
          <Text>No going back after you delete it.</Text>
        </Stack>
      </ModalSection>
      <ModalSection suppressed>
        <Stack direction="row">
          <Button onClick={handleConfirm}>Yes, delete!</Button>
          <Button type="secondary" onClick={cancel}>
            Cancel
          </Button>
        </Stack>
      </ModalSection>
    </Modal>
  );
};

export default ConfirmModal;
