import React, { useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import {
  Card,
  CardSection,
  Stack,
  Box,
  Button,
  Heading,
  InputField,
  Loading,
  Text,
} from "@kiwicom/orbit-components";

// Utils
import { login } from "../../utils/api/auth";

// Styles
import { Wrapper } from "./Styled";

const Login = () => {
  const [{ token }, setCookie] = useCookies(["token"]);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  if (token) {
    // eslint-disable-next-line
    location.replace("/");
  }

  const handleSetCookie = useCallback(
    (token: string) => {
      setCookie("token", token, { path: "/" });
      // eslint-disable-next-line
      // location.reload();
    },
    [setCookie]
  );

  const handleSubmit = useCallback(
    (e: any) => {
      if (email && password) {
        setLoading(true);
        login(email, password)
          .then((response) => {
            if (response.data.token) {
              handleSetCookie(response.data.token);
            }
            setLoading(false);
          })
          .catch((error) => {
            setErrorMessage(error.response.data.message);
            setLoading(false);
          });
      }
    },
    [email, password, handleSetCookie]
  );

  const handleEmailChange = useCallback((e: any) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e: any) => {
    setPassword(e.target.value);
  }, []);

  return (
    <Wrapper>
      <Box justify="center" display="inline-flex" width="400px">
        <Card>
          <CardSection>
            <Heading spaceAfter="large" as="h1">
              Login
            </Heading>
            {loading && <Loading type="pageLoader" />}
            {!loading && (
              <Stack spacing="large">
                <Stack spacing="XSmall">
                  <InputField
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Your email"
                  />
                  <InputField
                    value={password}
                    onChange={handlePasswordChange}
                    type="password"
                    placeholder="Your password"
                  />
                  {errorMessage && <Text type="critical">{errorMessage}</Text>}
                </Stack>
                <Button onClick={handleSubmit} fullWidth>
                  Login
                </Button>
              </Stack>
            )}
          </CardSection>
        </Card>
      </Box>
    </Wrapper>
  );
};

export default Login;
