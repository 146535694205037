import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  Card,
  CardSection,
  Stack,
  Box,
  Heading,
  Loading,
} from "@kiwicom/orbit-components";

// Utils
import { getNewsletterEmails } from "../../utils/api/newsletter";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Types
import { Newsletter as NewsletterType } from "../../static/types/newsletter";

// Styles
import { Content } from "./Styled";

const Newsletter = () => {
  const [{ token }] = useCookies(["token"]);
  const [loading, setLoading] = useState<boolean>(false);
  const [emails, setPosts] = useState<NewsletterType[]>([]);

  if (!token) {
    window.location.replace("/login");
  }

  useEffect(() => {
    getNewsletterEmails(token, setPosts, setLoading);
  }, [token]);

  return (
    <Wrapper>
      <Content>
        <Container animate>
          <Stack>
            <Box
              padding={{ left: "medium", right: "medium" }}
              tablet={{ padding: { left: "none", right: "none" } }}
            >
              <Heading>Newsletter</Heading>
            </Box>
            <Card>
              {loading && <Loading type="pageLoader" />}
              {emails.length &&
                emails.map((email: NewsletterType) => (
                  <CardSection key={email.id}>{email.email}</CardSection>
                ))}
            </Card>
          </Stack>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default Newsletter;
