import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Navigation from "./components/Navigation";
// Views
import BlogView from "./views/Blog/Blog";
import ReferenceView from "./views/Reference/Reference";
import CatalogView from "./views/Catalog/Catalog";
import HorseEditView from "./views/HorseEdit/HorseEdit";
import HorseCreateView from "./views/HorseCreate/HorseCreate";
import BlogCreateView from "./views/BlogCreate/BlogCreate";
import BlogEditView from "./views/BlogEdit/BlogEdit";
import NewsletterView from "./views/Newsletter/Newsletter";
import LoginView from "./views/Login/Login";

function App() {
  return (
    <Router>
      <>
        <Navigation />

        <Switch>
          <Route path="/" exact>
            <CatalogView />
          </Route>
          <Route path="/blog" exact>
            <BlogView />
          </Route>
          <Route path="/reference" exact>
            <ReferenceView />
          </Route>
          <Route path="/catalog" exact>
            <CatalogView />
          </Route>
          <Route path="/catalog/:id" exact>
            <HorseEditView />
          </Route>
          <Route path="/create/horse" exact>
            <HorseCreateView />
          </Route>
          <Route path="/create/blog" exact>
            <BlogCreateView />
          </Route>
          <Route path="/blog/:id" exact>
            <BlogEditView />
          </Route>
          <Route path="/newsletter" exact>
            <NewsletterView />
          </Route>
          <Route path="/login" exact>
            <LoginView />
          </Route>
        </Switch>
      </>
    </Router>
  );
}

export default App;
