import styled from "styled-components";

// Animations
import { animateMainMenu } from "../../utils/animations/keyframes";

export const Wrapper = styled.nav`
  width: 100%;
  height: 80px;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;

  animation-name: ${animateMainMenu};
  animation-duration: 0.6s;
`;

export const Branding = styled.div`
  display: flex;
  height: 50px;

  img {
    height: 50px;
  }
`;
