import React from "react";
import ReactDOM from "react-dom";
import ThemeProvider from "@kiwicom/orbit-components/lib/ThemeProvider";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";

// Global styles
import "./styles/reset.css";
import "./styles/font.css";

import App from "./App";

const customTokens = getTokens({
  palette: {
    product: {
      light: "#F0A04C",
      lightHover: "#D69045",
      lightActive: "#BA7D3C",
      normal: "#8A171A",
      normalHover: "#7c1417",
      normalActive: "#6e1214",
      dark: "#6e1214",
    },
  },
  base: {
    borderRadius: "10px",
    fontFamily: "Roboto Slab",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={{ orbit: customTokens }}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
